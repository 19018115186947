<template>
    <div class="row">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="panel-container show">
                    <div class="panel-content">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                                <el-date-picker class="w-100 mt-3"
                                                v-model="form.date"
                                                type="week"
                                                :format="view"
                                                :picker-options="pickerOptions"
                                                value-format="yyyy-MM-dd"
                                                @change="changeDate"
                                                placeholder="Chọn tuần">
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <div class="mb-5 mt-5" v-if="form.date" >
                            <h4>Lịch đăng kí từ ngày <b class="text-primary">{{ form.start }}</b> đến ngày <b class="text-primary">{{ form.end }}</b></h4>
                        </div>
                        <!-- datatable start -->
                        <el-empty v-if="!form.date" description="Bạn chưa chọn ngày"></el-empty>
                        <el-row v-else v-loading="loading.loadingCalendar" class="table-responsive-lg">
                            <table class="table table-bordered m-0">
                                <thead>
                                <tr>
                                    <th class="action-date">
                                        <el-popover placement="right" width="200" v-model="visible.pre">
                                            <p class="text-break"><i class="el-icon-warning mr-2 text-warning" ></i>Bạn vẫn muốn tiếp tục khi chưa lưu thông tin?</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="text" @click="visible.pre = false">Hủy</el-button>
                                                <el-button type="primary" size="mini" @click="visible.pre = false; preDate()">Tiếp tục</el-button>
                                            </div>
                                            <!--eslint-disable-->
                                            <i slot="reference" @click="checkVisible(false)" class="el-icon-arrow-left"></i>
                                        </el-popover>
                                    </th>
                                    <th class="text-center" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <span class="text-capitalize">{{item.dayOfWeek }}</span> <br> {{ item.day }}
                                    </th>

                                    <th class="action-date">
                                        <el-popover placement="right" width="200" v-model="visible.next">
                                            <p class="text-break"><i class="el-icon-warning mr-2 text-warning" ></i>Bạn vẫn muốn tiếp tục khi chưa lưu thông tin?</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="text" @click="visible.next = false">Hủy</el-button>
                                                <el-button type="primary" size="mini" @click="visible.next = false; nextDate()">Tiếp tục</el-button>
                                            </div>
                                            <!--eslint-disable-->
                                            <i slot="reference" @click="checkVisible(true)" class="el-icon-arrow-right"></i>
                                        </el-popover>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row" class="session"> Buổi sáng</th>
                                    <td class="text-center" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current,
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <el-checkbox
                                                :disabled="morningDisable.includes(item.day) || disableCheckbox(item.day)"
                                                v-model="morning" class="checkbox-calendar"
                                                :label="item.day"
                                                :key="item.day">
                                        </el-checkbox>
                                        <div v-if="morningDisable.includes(item.day) && getLeaderSessions(item.day, 'leaderMorning')">
                                            Leader: {{ getLeaderSessions(item.day, 'leaderMorning') }}
                                        </div>
                                    </td>
                                    <th scope="row" class="session"> Buổi sáng</th>
                                </tr>
                                <tr>
                                    <th scope="row" class="session top"> Buổi chiều</th>
                                    <td class="text-center" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current,
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <el-checkbox :disabled="afternoonDisable.includes(item.day) || disableCheckbox(item.day)" v-model="afternoon" class="checkbox-calendar" :label="item.day" :key="item.day"></el-checkbox>
                                        <div v-if="eveningDisable.includes(item.day) && getLeaderSessions(item.day, 'leaderAfternoon')">
                                            Leader: {{ getLeaderSessions(item.day, 'leaderAfternoon') }}
                                        </div>
                                    </td>
                                    <th scope="row" class="session top"> Buổi chiều</th>
                                </tr>
                                <tr>
                                    <th scope="row" class="session top"> Buổi tối</th>
                                    <td class="text-center" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <el-checkbox :disabled="eveningDisable.includes(item.day) || disableCheckbox(item.day)" v-model="evening" class="checkbox-calendar" :label="item.day" :key="item.day"></el-checkbox>
                                        <div v-if="eveningDisable.includes(item.day) && getLeaderSessions(item.day, 'leaderEvening')">
                                            Leader: {{ getLeaderSessions(item.day, 'leaderEvening') }}
                                        </div>
                                    </td>
                                    <th scope="row" class="session top"> Buổi tối</th>
                                </tr>
                                </tbody>
                            </table>
                        </el-row>
                        <el-button  v-if="form.date" :disabled="disableButtonSave()"  class="mt-3" @click="saveRegister()" type="primary" :loading="loading.saveCalendar">Đăng ký lịch làm ngay</el-button>
                        <!-- datatable end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {REGISTER_WORK, REGISTER_STORE} from "@/core/services/store/service.module";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    import {mapGetters} from "vuex";

    export default {
        name: "EdutalkWork",
        data() {
            return {
                loading: {
                    loadingCalendar: false,
                    saveCalendar :false
                },
                fullScreen: {
                    isFullCalendar: false
                },
                pickerOptions: {
                    firstDayOfWeek: 1,
                },
                form: {
                    date: null,
                    start: null,
                    end: null,
                },
                table: {
                    columns: [],
                },
                current: null,
                morning: null,
                afternoon: null,
                evening: null,
                morningDisable: null,
                afternoonDisable: null,
                eveningDisable: null,
                view: null,
                visible: {
                    pre: null,
                    next: null
                },
                transform: {
                    morning: 'Buổi sáng',
                    afternoon: 'Buổi chiều',
                    evening: 'Buổi tối',
                },
                tableData: [],
                getData: null
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Sale đăng ký lịch", icon: 'far fa-calendar-alt'}
            ]);
            this.current = this.$moment().format('YYYY-MM-DD');
            this.form.date = this.$moment().format('YYYY-MM-DD');
            this.convertViewDate();
        },
        methods: {
            changeDate() {
                this.table.columns = [];
                this.convertViewDate();
            },
            convertViewDate() {
                let begin = this.$moment(this.form.date, 'YYYY-MM-DD').startOf('week');
                let end = this.$moment(this.form.date, 'YYYY-MM-DD').endOf('week');
                this.form.start = begin.format('YYYY-MM-DD');
                this.form.end = end.format('YYYY-MM-DD');
                this.view = (this.form.start + ' đến ' + this.form.end);
                this.table.columns.push({ 'dayOfWeek': begin.format('dddd'), 'day': begin.format('YYYY-MM-DD') });
                for (let i = 0; i < 6; i ++) {
                    let data = begin.add(1, 'days');
                    this.table.columns.push({ 'dayOfWeek': data.format('dddd'), 'day': data.format('YYYY-MM-DD') })
                }
                this.reloadModel();
            },
            disableButtonSave() {
                let morning = _.difference(this.morning, this.morningDisable);
                let afternoon = _.difference(this.afternoon, this.afternoonDisable);
                let evening = _.difference(this.evening, this.eveningDisable);
                return morning.length === 0 && afternoon.length === 0 && evening.length === 0;
            },
            checkVisible(isNext) {
                this.visible.next = true;
                this.visible.pre = true;
                let morning = _.difference(this.morning, this.morningDisable);
                let afternoon = _.difference(this.afternoon, this.afternoonDisable);
                let evening = _.difference(this.evening, this.eveningDisable);
                if (morning.length === 0 && afternoon.length === 0 && evening.length === 0 ) {
                    if (isNext) {
                        this.nextDate();
                    } else {
                        this.preDate();
                    }
                } else {
                    if (isNext) {
                        this.visible.next = false;
                    } else {
                        this.visible.pre = false;
                    }
                }
            },
            nextDate() {
                this.loading.loadingCalendar = true;
                this.table.columns = [];
                let nextDate = this.$moment(this.form.date, 'YYYY-MM-DD').endOf('week').add(1, 'days');
                this.form.date = nextDate.format('YYYY-MM-DD');
                this.convertViewDate();

            },
            preDate() {
                this.loading.loadingCalendar = true;
                this.table.columns = [];
                let preDate = this.$moment(this.form.date, 'YYYY-MM-DD').startOf('week').subtract(1, 'days');
                this.form.date = preDate.format('YYYY-MM-DD');
                this.convertViewDate();
            },
            reloadModel: function () {
                this.clearSession();
                let param = this.mergeParams();
                this.loading.loadingCalendar = true;
                this.$store.dispatch(REGISTER_WORK, param)
                    .then((response) => {
                        this.getData = response;
                        _.each(response, (value) => {

                            if (value.morning) {
                                this.morning.push(value.date);
                                this.morningDisable.push(value.date)
                            }
                            if (value.afternoon) {
                                this.afternoon.push(value.date);
                                this.afternoonDisable.push(value.date)
                            }
                            if (value.evening) {
                                this.evening.push(value.date);
                                this.eveningDisable.push(value.date)
                            }
                        });
                        this.loading.loadingCalendar = false;
                    });
            },
            clearSession() {
                this.morning = [];
                this.afternoon = [];
                this.evening = [];
                this.morningDisable = [];
                this.afternoonDisable = [];
                this.eveningDisable = [];
            },
            mergeParams(customProperties) {
                let params = { };
                if (this.form.start) {
                    params = _.merge(params, {startDay: this.form.start})
                }
                if (this.form.end) {
                    params = _.merge(params, {endDay: this.form.end })
                }
                params = _.merge(params, customProperties);
                return params;
            },
            disableCheckbox(item) {
                return item < this.current || item === this.current
            },
            getLeaderSessions(day, leaderSession) {
                if (this.getData[day] && typeof this.getData[day][leaderSession] != 'undefined') {
                    return this.getData[day][leaderSession]
                }
                return ''
            },
            saveRegister() {
                let morning = _.difference(this.morning, this.morningDisable);
                let afternoon = _.difference(this.afternoon, this.afternoonDisable);
                let evening = _.difference(this.evening, this.eveningDisable);
                this.loading.saveCalendar = true;
                let params = {
                    morning: morning,
                    afternoon: afternoon,
                    evening: evening
                };
                this.$store.dispatch(REGISTER_STORE, params)
                    .then((response) => {
                        if (response.status === 500 || response.status === 422) {
                            this.$message({ type: 'error', message: `${response.message}`, showClose: true });
                        } else  {
                            let data = response.data;
                            _.map(data, (value) => {
                                if (value.morning) {
                                    value.leaderMorning = value.morning
                                }
                                if (value.afternoon) {
                                    value.leaderAfternoon = value.afternoon
                                }
                                if (value.evening) {
                                    value.leaderEvening = value.evening
                                }
                                return value
                            });
                            _.merge(this.getData, data);
                            _.each(this.morning, (item) => {
                                this.morningDisable.push(item)
                            });
                            _.each(this.afternoon, (item) => {
                                this.afternoonDisable.push(item)
                            });
                            _.each(this.evening, (item) => {
                                this.eveningDisable.push(item)
                            });
                            this.$message({ type: 'success', message: `${response.message}`, showClose: true });
                        }
                        this.loading.saveCalendar = false;
                    });
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    };

</script>
